<template>
  <div class="shed-space">
    <div class="search">
      <div class="search-box">
        <div class="search-item">
          <div class="search-item__label">棚位：</div>
          <el-input
            v-model="searchForm.shedCodeOrName"
            size="small"
            placeholder="输入棚位名称/ID"
            style="width: 200px"
          ></el-input>
        </div>
        <!-- <div class="search-item">
          <div class="search-item__label">棚位类型：</div>
          <el-select v-model="searchForm.specifications" size="small" placeholder="选择棚位类型" style="width: 200px">
          </el-select>
        </div> -->
        <!-- <div class="search-item">
          <div class="search-item__label">状态：</div>
          <el-select v-model="searchForm.isDisable" size="small" placeholder="选择棚位状态" style="width: 200px">
            <el-option label="启用" :value="0"></el-option>
            <el-option label="停用" :value="1"></el-option>
          </el-select>
        </div> -->
      </div>
      <div class="search-button">
        <el-button type="primary" size="small" @click="onSearch">查询</el-button>
        <el-button type="" size="small" @click="onReset">重置</el-button>
        <el-button type="primary" size="small" @click="onAdd">新增棚位</el-button>
      </div>
    </div>
    <el-table :data="dataList" border>
      <el-table-column label="棚位ID" prop="shedCode" align="center"></el-table-column>
      <el-table-column label="棚位名称" prop="shedName" align="center"></el-table-column>
      <el-table-column label="棚位类型" prop="productName" align="center"></el-table-column>
      <el-table-column label="ERP棚位编号" prop="phtordno" align="center"></el-table-column>
      <!-- <el-table-column label="状态" prop="isDisable" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isDisable == 0 ? 'success' : 'info'">{{ scope.row.isDisable == 0 ? '启用' : '停用' }}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="handleEnable(scope.row)" type="text" size="small">{{ scope.row.isDisable == 1 ? '启用' : '停用' }}</el-button> -->
          <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="handleDetail(scope.row)">预约详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="page.current"
        :page-size="page.size"
        background
        :page-sizes="[10, 20, 30, 40]"
        layout="prev, pager, next, sizes, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog
      :title="shedSpaceForm.shedId ? '编辑棚位' : '创建棚位'"
      :visible.sync="dialogFormVisible"
      width="500px"
      @close="onClose"
    >
      <el-form ref="shedSpaceForm" :model="shedSpaceForm" :rules="shedSpaceRules">
        <el-form-item label="棚位名称：" label-width="120px" prop="shedName">
          <el-input
            v-model="shedSpaceForm.shedName"
            placeholder="请输入棚位名称"
            :maxlength="15"
          ></el-input>
        </el-form-item>
        <el-form-item label="棚位类型：" label-width="120px" prop="shedTypeId">
          <el-select v-model="shedSpaceForm.shedTypeId" size="small" placeholder="选择棚位类型">
            <el-option
              v-for="(item, index) in shedTypeOptions"
              :key="index"
              :label="item.productName"
              :value="item.shedTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="ERP棚位编号：" label-width="120px" prop="phtordno">
          <!-- <el-input v-model="shedSpaceForm.phtordno" placeholder="请输入ERP棚位编号"></el-input> -->
          <el-autocomplete
            v-model="shedSpaceForm.phtordno"
            value-key="phtstno"
            label="phtstname"
            :fetch-suggestions="querySearchStaffAsync"
            placeholder="请输入ERP棚位编号"
            style="width: 100%"
            popper-class="my-autocomplete"
          >
            <template slot-scope="{ item }">
              <div class="prdtname">
                <span>{{ item.phtstname + ' - ' }}</span>
                <span>{{ item.phtstno }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <!-- <el-form-item label="是否启用：" label-width="120px" prop="isDisable">
          <el-radio-group v-model="shedSpaceForm.isDisable">
            <el-radio :label="0">直接启用</el-radio>
            <el-radio :label="1">暂时不启用</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" :loading="formLoading" @click="onConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from '@/mixins/request.js'
import {
  getShedList,
  saveShed,
  updateShedListEnable,
  getShedTypeMapAll,
  searchSeat,
} from '@/api/shed-space.js'
import { selectByToken } from '@/api/user'
export default {
  mixins: [request],
  data() {
    return {
      dialogFormVisible: false,
      shedSpaceForm: {
        isDisable: 0,
        phtordno: '',
        shedTypeId: '',
        shedName: '',
      },
      shedSpaceRules: {
        shedName: [{ required: true, message: '请输入棚位名称', trigger: 'blur' }],
        shedTypeId: [{ required: true, message: '请选择棚位类型', trigger: 'change' }],
        isDisable: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
      },
      formLoading: false,
      shedTypeOptions: [],
      dptno: '',
    }
  },
  mounted() {
    this.getSelectByToken()
    this.getData(getShedList)
    this.shedTypeMapAll()
  },
  methods: {
    querySearchStaffAsync(queryString, cb) {
      searchSeat({
        data: {
          dptno: this.dptno,
          keyname: queryString,
        },
      }).then((res) => {
        cb(res.data)
      })
    },
    getSelectByToken() {
      selectByToken().then((res) => {
        console.log(res)
        this.dptno = res.data.erpDeptId
      })
    },
    shedTypeMapAll() {
      getShedTypeMapAll().then((res) => {
        console.log(res)
        this.shedTypeOptions = res.data
      })
    },
    handleDetail(row) {
      console.log(row)
      this.goBrandDetail(row.shedId)
    },
    onAdd() {
      this.dialogFormVisible = true
    },
    onClose() {
      this.dialogFormVisible = false
      this.shedSpaceForm = {
        isDisable: 0,
      }
      this.$refs.shedSpaceForm.resetFields()
    },
    handleEdit(e) {
      console.log(e)
      this.shedSpaceForm = {
        isDisable: e.isDisable,
        shedName: e.shedName,
        shedTypeId: e.shedTypeId,
        shedId: e.shedId,
        phtordno: e.phtordno,
      }
      this.dialogFormVisible = true
    },
    async confirm(e) {
      return await this.$confirm(`是否确认${e}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    },
    onConfirm() {
      this.formLoading = true

      saveShed({ data: this.shedSpaceForm })
        .then(() => {
          this.getData()
          this.formLoading = false
          this.onClose()
          this.$message.success('新增成功!')
        })
        .catch((e) => {
          this.formLoading = false
          this.$message.error(e.description || '新增失败!')
        })
    },
    async handleEnable({ shedId, isDisable }) {
      await this.confirm(isDisable === 1 ? '启用' : '停用')
      updateShedListEnable({ data: shedId })
        .then((res) => {
          console.log(res)
          this.getData()
          this.$message.success(isDisable === 1 ? '启用成功!' : '停用成功！')
        })
        .catch((e) => {
          this.$message.error(e.description || (isDisable === 1 ? '启用失败!' : '停用失败！'))
        })
    },
    goBrandDetail(id) {
      const routeUrl = this.$router.resolve({
        path: '/shedSpace/supplyShedSpaceDetail',
        query: { shedId: id },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.shed-space {
  padding: 12px;
  background-color: #fff;
}
.search {
  display: flex;
  &-item {
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-bottom: 24px;
    &__label {
      text-align: right;
      font-size: 13px;
      width: 70px;
    }
  }
  &-box {
    display: flex;
    flex-wrap: wrap;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .prdtname {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .brdtype {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .brdtype {
      color: #ddd;
    }
  }
}
</style>
